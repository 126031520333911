import {useEffect, useState} from "react";
import {isItUndefined, replaceGlobalMetrics, stripHtml} from "../../util/DataUtil";
import FacilityModal from "../modal/FacilityModal";
import CtaBarButton from "../base/cta-bar-button";
import {getObjectValueByMachineName} from "../../util/ObjectUtil";

const FacilityCtaBar = ({lang, children, facilityData,metroData, locationData,regionData,  global, width}) => {
    const dataSet = Object.assign({}, facilityData, metroData, locationData, regionData);

    const [fontSize, setFontSize] = useState(16);
    const [marketoTrigger, setMarketoTrigger] = useState(false);
    const [isResourcesTemplate, setIsResourcesTemplate] = useState(false);
    let contact = facilityData?.field_sales_contact_location;
    let support = facilityData?.field_sales_support_location;
    let background = isItUndefined(children?.field_text_color);

    let text = getObjectValueByMachineName(children?.field_cta_bar_text[0]?.value, dataSet)
    let isHeadlineAddress = children?.field_cta_bar_headline[0]?.value.includes('[[field_address_location]]')
    let isTextAddress = children?.field_cta_bar_text[0]?.value.includes('[[field_address_location]]')
    let type = isItUndefined(children?.field_cta_bar_type)
    let contactCTA = isItUndefined(children?.field_cta_bar_sales)
    let supportCTA = isItUndefined(children?.field_cta_bar_support)
    let contactNumber = isItUndefined(children?.field_cta_bar_contact_number)
    let headline = getObjectValueByMachineName(children?.field_cta_bar_headline[0]?.value, dataSet)
    let contactSuper = global ? getObjectValueByMachineName(contact ? contact : contactCTA, global) : '';
    let supportSuper = global ? getObjectValueByMachineName(support ? support : supportCTA, global) : '';

    let btn1 = {
        text : children?.field_cta_bar_button?.length > 0 ? children?.field_cta_bar_button[0]?.cta_bar_button?.field_cta_bar_button_text_1[0]?.value : ''
    }

    let btn2 = {
        text : children?.field_cta_bar_button?.length == 2 ? children.field_cta_bar_button[1].cta_bar_button?.field_cta_bar_button_text_1[0]?.value : ''
    }


    useEffect(() => {
        if (btn1.text === undefined){
            btn1.text = ''
        }
        if (btn2.text === undefined){
            btn2.text = ''
        }

        if(width <= 576){
            setFontSize(16)
        }else{
            setFontSize(16)
        }
    }, [width])

    const getBg = () => {
        if(!background) return 'black';

        return background?.toLowerCase();
    }

    const getType = (type) => {
        if(!type) return;

        if (type === '0'){
            return 'download ' + getBg()
        }else if (type === '1'){
            return 'location '+ getBg()
        }else if (type === '2'){
            return 'contact '+ getBg()
        }
    }

    const isIconLight = (color) => {
        if(!color.includes('light') && (color.includes('dark') || color.includes('slate-grey') || color.includes('violet') || color.includes('blue') || color.includes('black'))){
            return false;
        }

        return true;
    }
    
    useEffect(() => {        
        const gatedExists = !!document.querySelector('.gated_template');
        const mediaExists = !!document.querySelector('.media_template');
        const nonGatedExists = !!document.querySelector('.non_gated_template');
        const pressReleaseExists = !!document.querySelector('.press_release');
        if (gatedExists || mediaExists || nonGatedExists || pressReleaseExists) {
            setIsResourcesTemplate(true);
        }
      }, []);
      
    return <>
        {marketoTrigger && <FacilityModal lang={lang} global={global} setTrigger={setMarketoTrigger} trigger={marketoTrigger}>{children?.field_cta_bar_button?.length > 0 && children?.field_cta_bar_button[0]?.cta_bar_button?.field_marketo_blocks.length > 0 ? children?.field_cta_bar_button[0]?.cta_bar_button?.field_marketo_blocks[0]?.marketo_form_facility_block_type : children?.field_cta_bar_button?.length == 2  && children?.field_cta_bar_button[1]?.cta_bar_button?.field_marketo_blocks.length > 0 ? children?.field_cta_bar_button[1]?.cta_bar_button?.field_marketo_blocks[0]?.marketo_form_facility_block_type : []}</FacilityModal>}
        <div className={`main-marketo inner-container ${isResourcesTemplate ? 'resources-cta-bar' : ''} cta-bar ${getType(type)} ${getBg()} ${btn1.text !== '0' && btn2.text !== '0' && btn1.text !== undefined && btn2.text !== undefined ? 'two-buttons' : ''}`}>
        <div className={'left'}>
            {
                getType(type)?.includes('location') && <img
                    className={`location-icon-${!isIconLight(getType(type)) ? 'white' : 'black'}`}
                    alt="cta"
                    src={'/images/location.svg'} />
            }
            {
                (getType(type)?.includes('download') && !isIconLight(getType(type))) && <img alt="cta" src={'/images/cta-download.png'} />
            }
            {
                getType(type)?.includes('download') && isIconLight(getType(type)) && <img alt="cta" src={'/images/download-black.png'} />
            }
            <div className={'inner-left'}>
                {
                    headline &&
                    <span
                        onClick={()=> {
                            // if (getType(type) !== 'location'){
                            //     return
                            // }

                            if (!getType(type).includes('location') || !isHeadlineAddress){
                                return
                            }

                            if(window.navigator.platform === 'MacIntel'){
                                // window.open('http://maps.apple.com/?q=' + (text ? stripHtml(text) : ''))
                                window.open('http://maps.apple.com/?q=' + (headline ? stripHtml(headline) : ''))
                            }else{
                                // window.open('http://maps.google.com/?q=' + (text ? stripHtml(text) : ''))
                                window.open('http://maps.google.com/?q=' + (headline ? stripHtml(headline) : ''))
                            }

                        }}
                        dangerouslySetInnerHTML={{__html : headline}}
                        className={'headline ' + (isHeadlineAddress ? 'group-style' : '')}
                    />
                }{
                text &&
                <span className={'span-cta-bar-link text '+ (isTextAddress ? 'group-style' : '')} onClick={()=> {
                    // if (getType(type) !== 'location'){
                    //     return
                    // }

                    if (!getType(type).includes('location') || !isTextAddress){
                        return
                    }

                    if(window.navigator.platform === 'MacIntel'){
                        window.open('http://maps.apple.com/?q=' + (text ? stripHtml(text) : ''))
                    }else{
                        window.open('http://maps.google.com/?q=' + (text ? stripHtml(text) : ''))
                    }

                    }}
                    dangerouslySetInnerHTML={{__html : text}}
                />
                }
                {
                    (contactSuper) && (getType(type).includes('location'))  &&
                    <span className={'text number ' + ((isHeadlineAddress || isTextAddress) ? 'group-style' : '')} dangerouslySetInnerHTML={{__html : contactSuper}}
                    />
                }
                {
                    (supportSuper) && (getType(type).includes('location')) &&
                    <span className={'text number ' + ((isHeadlineAddress || isTextAddress) ? 'group-style' : '')} dangerouslySetInnerHTML={{__html : supportSuper}}
                    />
                }
            </div>
        </div>
        <div className={'right'}>
            {children?.field_cta_bar_button?.length > 0 ? <CtaBarButton blockTypeClass={'cta-bar'} setMarketoTrigger={setMarketoTrigger} fontSize={fontSize} bgColor={getBg()} data={children?.field_cta_bar_button?.length > 0 ? children?.field_cta_bar_button[0]?.cta_bar_button : []} /> : <></>}
            {children?.field_cta_bar_button?.length == 2 ? <CtaBarButton blockTypeClass={'cta-bar'} setMarketoTrigger={setMarketoTrigger} fontSize={fontSize} bgColor={getBg()} data={children?.field_cta_bar_button?.length == 2 ? children?.field_cta_bar_button[1]?.cta_bar_button : []} /> : <></>}
        </div>
    </div>
        </>
}

export default FacilityCtaBar;
